/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Button from './ButtonHead'
import Logo from './Logo'

import bgImage from '../static/images/footerbg.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
  },
  waveShadow: {
    paddingTop: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      background: 'radial-gradient(circle, rgba(0,0,0,0) 0%, rgba(28,32,57,1) 80%)',
    },
    [theme.breakpoints.up('md')]: {
      background: 'radial-gradient(circle, rgba(0,0,0,0) 0%, rgba(28,32,57,1) 70%)',
    },
    [theme.breakpoints.up('lg')]: {
      background: 'radial-gradient(circle, rgba(0,0,0,0) 0%, rgba(28,32,57,1) 50%)',
    },
  },
  wave: {
    marginTop: theme.spacing(10),
    background: `url(${bgImage}) no-repeat top center`,
    backgroundSize: '100%',
    [theme.breakpoints.up('xs')]: {
      backgroundSize: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      backgroundSize: '100%',
    },
    [theme.breakpoints.up('md')]: {
      backgroundSize: 'contain',
    },
    // [theme.breakpoints.up('lg')]: {
    //   backgroundSize: '70%',
    // },
  },
  container: {
    overflow: 'hidden',
  },
  footer: {
    width: '180%',
    marginLeft: '-40%',
    borderRadius: '100% 100% 50% 50%',
    minHeight: theme.spacing(50),
    background: 'rgba(105, 110, 236, 0.1)',
  },
  content: {
    marginTop: theme.spacing(40) * -1,
  },
  button: {
    paddingTop: theme.spacing(3),
  },
  textContainer: {
    marginTop: theme.spacing(10),
  },
  text: {
    textAlign: 'center',
    fontSize: 22,
    fontWeight: 'bold',
    color: '#606374',
  },
  image: {
    width: '100%',
    height: 'auto',
  },
}))

function Footer({ link }) {
  const classes = useStyles()

  return (
    <Grid container justifyContent="center" className={classes.root}>
      <Grid item xs={12}>
        <Grid container className={classes.wave}>
          <Grid item xs={12} className={classes.waveShadow}>
            <div className={classes.footer} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid
          container
          justifyContent="center"
          className={classes.content}
        >
          <Grid item xs={12}>
            <Logo link={link} />
          </Grid>
          <Grid container justifyContent="center" className={classes.button}>
            <Grid
              item
              xs={10}
              md={4}
            >
              <Button href={link}>Try For Free</Button>
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.textContainer}>
            <Typography className={classes.text}>© 2021 by Azur Apps</Typography>
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  )
}

Footer.propTypes = {
  link: PropTypes.string.isRequired,
}

export default Footer
