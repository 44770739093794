/* eslint-disable no-console */
/* eslint-disable no-debugger */
import React, { useState, useEffect } from 'react'
import LangdingPage from './components/LangdingPage'
import { LogView, LogClick } from './Log'

const Init = (url) => new Promise((resolve, reject) => {
  const attempts = 5
  const interval = 300
  const timerId = setInterval(() => {
    if (attempts <= 0) {
      clearInterval(timerId)
      reject(Error('cookie is not set'))
    }

    const decodedCookie = decodeURIComponent(document.cookie)
    if ((decodedCookie.indexOf('_fbp') > -1) || (decodedCookie.indexOf('_fbc') > -1)) {
      clearInterval(timerId)
      fetch(url, {
        method: 'GET',
        cache: 'no-cache',
        credentials: 'include',
      }).then((response) => {
        if (!response.ok) {
          reject(Error('invalid init response'))
        } else {
          if (response.status === 201) {
            LogView()
          }
          resolve(response)
        }
      })
    }
  }, interval)
})

function App() {
  const [link, setLink] = useState(`${process.env.REACT_APP_APPSFLYER_ONELINK_BASE_URL}`)

  const apiBaseURL = `${process.env.REACT_APP_API_BASE_URL}`
  const pixelID = `${process.env.REACT_APP_PIXEL_ID}`
  const url = new URL(window.location.href)
  const c = url.searchParams.get('c')
  const apiURL = `${apiBaseURL}/api/init?id=${pixelID}&c=${c}&u=${encodeURIComponent(url.toString())}`

  useEffect(() => {
    Init(apiURL)
      .then(() => {
        fetch(`${apiBaseURL}/api/value`, {
          method: 'GET',
          cache: 'no-cache',
          credentials: 'include',
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('invalid value response')
            }
            return response.json()
          })
          .then(({ value }) => {
            const l = `${process.env.REACT_APP_APPSFLYER_ONELINK_BASE_URL}&deep_link_value=${value}&af_force_deeplink=true&c=${c}`
            setLink(l)
            console.log(l)
            setTimeout(() => {
              LogClick()
              // eslint-disable-next-line no-undef
              fbq('track', 'Lead')

              window.location.href = l
            }, 2000)
          })
      })
  // Run useEffect Only Once
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="App">
      <LangdingPage link={link} />
    </div>
  )
}

export default App
