/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import { Container, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Button from './ButtonHead'
import Logo from './Logo'

import BgImage from '../static/images/headbg.jpg'

const useStyles = makeStyles((theme) => ({
  container: {
    background: `url(${BgImage}) no-repeat top center`,
    position: 'relative',
    backgroundColor: '#1C2039',
    backgroundSize: 'cover',
    minHeight: '70vh',
  },
  logo: {
    marginTop: theme.spacing(2),
  },
  textContainer: {
    marginTop: theme.spacing(30),
  },
  text: {
    textAlign: 'center',
    color: '#fff',
    fontWeight: '700',
  },
  button: {
    marginTop: theme.spacing(10),
  },
  shadowTop: {
    position: 'absolute',
    width: '100%',
    height: '137px',
    background: 'linear-gradient(180deg, rgba(28, 32, 57, 0.7) 0%, rgba(28, 32, 57, 0) 100%)',
    zIndex: '1',
  },
  shadowBottom: {
    position: 'absolute',
    bottom: '0',
    width: '100%',
    height: '137px',
    background: 'linear-gradient(180deg, rgba(28, 32, 57, 0) 0%, #1C2039 100%)',
  },
  content: {
    height: '100%',
    position: 'relative',
    zIndex: '2',
  },
}))

function Head({ link }) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.shadowTop} />
      <div className={classes.shadowBottom} />

      <Grid
        container
        justifyContent="center"
        className={classes.content}
      >
        <Grid
          item
          xs={12}
        >
          <Grid
            container
            direction="column"
            alignContent="center"
            justifyContent="space-evenly"
          >
            <Grid item xs={12} className={classes.logo}>
              <Logo link={link} />
            </Grid>

            <Grid item xs={12} className={classes.textContainer}>
              <Typography className={classes.text} variant="h3">Your personal sleep assistant</Typography>
            </Grid>

            <Grid item xs={12} className={classes.button}>
              <Grid container justifyContent="center">
                <Grid item xs={10}>
                  <Button href={link}>Try For Free</Button>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </Grid>
      </Grid>

    </div>
  )
}

Head.propTypes = {
  link: PropTypes.string.isRequired,
}

export default Head
