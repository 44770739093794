/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import { Container, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Head from './Head'
import Ellipse from './Ellipse'
import Carousel from './Carousel'
import Steps from './Steps'
import Footer from './Footer'

const useStyles = makeStyles((theme) => ({
  carousel: {
    marginTop: theme.spacing(20) * -1,
  },
}))

function LangdingPage({ link }) {
  const classes = useStyles()

  return (
    <Container disableGutters maxWidth="false">
      <Grid
        container
        justifyContent="center"
        style={{
          position: 'relative',
          overflow: 'hidden',
        }}
      >

        <Grid item xs={12}>
          <Head link={link} />
        </Grid>

        <Grid item xs={12}>
          <Ellipse />
        </Grid>

        <Grid item xs={12} className={classes.carousel}>
          <Grid container justifyContent="center">
            <Grid item>
              <Carousel />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={10}>
          <Steps />
        </Grid>

        <Grid item xs={12}>
          <Footer link={link} />
        </Grid>

      </Grid>
    </Container>
  )
}

LangdingPage.propTypes = {
  link: PropTypes.string.isRequired,
}

export default LangdingPage
