/* eslint-disable no-unused-vars */
import React from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

// import BgImage from '../static/images/ellipsebg.svg'
import bestImage from '../static/images/best.svg'
import waveImage from '../static/images/waves.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: '#1C2039',
    paddingBottom: theme.spacing(3),
  },
  ellipse: {
    width: '220%',
    marginLeft: '-60%',
    borderRadius: '50%',
    backgroundColor: '#2A2E4C',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(15),
    height: theme.spacing(40),
    [theme.breakpoints.up('xs')]: {
      height: theme.spacing(40),
    },
    [theme.breakpoints.up('sm')]: {
      height: theme.spacing(45),
    },
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(50),
    },
    // [theme.breakpoints.up('lg')]: {
    //   height: theme.spacing(),
    // },
  },
  imageContainer: {
    marginTop: theme.spacing(30) * -1,
    [theme.breakpoints.up('xs')]: {
      marginTop: theme.spacing(37) * -1,
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(45) * -1,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(45) * -1,
    },
    // [theme.breakpoints.up('lg')]: {
    //   marginTop: theme.spacing(60) * -1,
    // },
  },
  image: {
    width: '100%',
    height: 'auto',
  },
  wave: {
    background: `url(${waveImage}) no-repeat center center`,
    backgroundSize: '100%',
    [theme.breakpoints.up('xs')]: {
      backgroundSize: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      backgroundSize: '100%',
    },
    [theme.breakpoints.up('md')]: {
      backgroundSize: 'contain',
    },
    // [theme.breakpoints.up('lg')]: {
    //   backgroundSize: '70%',
    // },
    height: theme.spacing(40),
    marginTop: theme.spacing(40) * -1,
  },
}))

function Ellipse() {
  const classes = useStyles()

  return (
    <Grid container justifyContent="center" className={classes.root}>
      <Grid item xs={12}>
        <div className={classes.ellipse} />
      </Grid>
      <Grid item xs={9} sm={7} md={4} lg={2} className={classes.imageContainer}>
        <img
          className={classes.image}
          src={bestImage}
          alt=""
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={12} md={12} className={classes.wave} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Ellipse
