import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import arrowImage from '../static/images/arrow.svg'

const useStyles = makeStyles((theme) => ({
  steps: {
    marginTop: theme.spacing(8),
    '& > div > div:last-child > div:nth-child(1)': {
    },
  },
  stepItemNumber: {
    color: '#fff',
    fontSize: '25px',
    fontWeight: 'bold',
    lineHeight: '54px',
    textAlign: 'center',
    verticalAlign: 'center',
    borderRadius: '50%',
    background: 'radial-gradient(73% 73% at 72.74% 16.99%, #2DAED0 0%, #3A8ABE 63.02%, #4A60A8 100%)',
    width: '52px',
    height: '52px',
  },
  stepItemText: {
    paddingTop: theme.spacing(1),
    color: '#fff',
    fontSize: 20,
    lineHeight: '30px',
    [theme.breakpoints.up('md')]: {
      fontSize: 25,
      lineHeight: '35px',
    },
    '& span': {
      padding: theme.spacing(0.5),
      backgroundColor: '#334560',
      color: '#fff',
      border: '1px solid #31A5CC',
      borderRadius: '9px',
    },
  },
  arrow: {
    background: `url(${arrowImage}) no-repeat`,
    backgroundPosition: '20px center',
    height: theme.spacing(8),
  },

}))

function Steps() {
  const classes = useStyles()

  return (
    <Grid container className={classes.steps}>

      <Grid item xs={12}>
        <Grid
          container
          justifyContent="center"
        >
          <Grid item xs={3} sm={2} lg={1} xl={1} className={classes.stepItem}>
            <Typography className={classes.stepItemNumber}>1</Typography>
            <div className={classes.arrow} />
          </Grid>
          <Grid item xs={8} sm={5} lg={3} xl={2}>
            <Typography className={classes.stepItemText}>
              <span>Build</span>
              {' '}
              healthy sleep habits
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid
          container
          justifyContent="center"
        >
          <Grid item xs={3} sm={2} lg={1} xl={1} className={classes.stepItem}>
            <Typography className={classes.stepItemNumber}>2</Typography>
            <div className={classes.arrow} />
          </Grid>
          <Grid item xs={8} sm={5} lg={3} xl={2}>
            <Typography className={classes.stepItemText}>
              <span>Track</span>
              {' '}
              your bedtime progress
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid
          container
          justifyContent="center"
        >
          <Grid item xs={3} sm={2} lg={1} xl={1} className={classes.stepItem}>
            <Typography className={classes.stepItemNumber}>3</Typography>
            <div className={classes.arrow} />
          </Grid>
          <Grid item xs={8} sm={5} lg={3} xl={2}>
            <Typography className={classes.stepItemText}>
              <span>Get</span>
              {' '}
              detailed sleep insights
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid
          container
          justifyContent="center"
        >
          <Grid item xs={3} sm={2} lg={1} xl={1} className={classes.stepItem}>
            <Typography className={classes.stepItemNumber}>4</Typography>
            <div className={classes.arrow} />
          </Grid>
          <Grid item xs={8} sm={5} lg={3} xl={2}>
            <Typography className={classes.stepItemText}>
              <span>Wake up</span>
              {' '}
              people you care about
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid
          container
          justifyContent="center"
        >
          <Grid item xs={3} sm={2} lg={1} xl={1} className={classes.stepItem}>
            <Typography className={classes.stepItemNumber}>5</Typography>
          </Grid>
          <Grid item xs={8} sm={5} lg={3} xl={2}>
            <Typography className={classes.stepItemText}>
              <span>Track and discover</span>
              {' '}
              your sleep patterns
            </Typography>
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  )
}

export default Steps
