/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Grid } from '@material-ui/core'
import { Carousel } from 'react-responsive-carousel'
import { makeStyles } from '@material-ui/core/styles'

import PhoneImage from '../static/images/phone3x.png'

import Slide1 from '../static/images/1slide3x.jpg'
import Slide2 from '../static/images/2slide3x.jpg'
import Slide3 from '../static/images/3slide3x.jpg'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '210px',
    height: '434px',
    [theme.breakpoints.up('sm')]: {
      width: '273px',
      height: '564px',
    },
    overflow: 'hidden',
  },
  phone: {
    position: 'absolute',
    backgroundImage: `url(${PhoneImage})`,
    backgroundSize: '210px 434px',
    top: 0,
    left: 0,
    width: '210px',
    height: '434px',
    [theme.breakpoints.up('sm')]: {
      width: '273px',
      height: '564px',
      backgroundSize: '273px 564px',
    },
    zIndex: '99',
    backgroundRepeat: 'no-repeat',
  },
  carousel: {
    position: 'absolute',
    top: '11px',
    left: '12px',
    width: '187px',
    height: '412px',
    [theme.breakpoints.up('sm')]: {
      width: '243px',
      height: '535px',
    },
    borderRadius: '20px',
  },
  slide: {
    height: '412px',
    [theme.breakpoints.up('sm')]: {
      height: '535px',
    },
  },
  controls: {
    marginTop: theme.spacing(1),
  },
  controlItem: {
    transition: 'background-color 0.7s ease',
    margin: theme.spacing(1),
    borderRadius: '50%',
    width: '12px',
    height: '12px',
    backgroundColor: 'rgba(105, 110, 236, 0.3)',
  },
  controlItemSelected: {
    backgroundColor: '#696EEC',
    margin: theme.spacing(1),
    borderRadius: '50%',
    width: '12px',
    height: '12px',
  },
}))

function CustomCarousel() {
  const classes = useStyles()

  const [selectedItem, setSelectedItem] = useState(0)

  const slides = [
    Slide1,
    Slide2,
    Slide3,
  ]

  const onChange = (i) => {
    setSelectedItem(i)
  }

  return (
    <Grid
      container
      direction="column"
    >
      <Grid item>
        <div className={classes.container}>
          <div className={classes.phone} />
          <div className={classes.carousel}>
            <Carousel
              showThumbs={false}
              autoPlay
              infiniteLoop
              showStatus={false}
              interval={1500}
              showIndicators={false}
              selectedItem={selectedItem}
              onChange={onChange}
            >
              {slides.map((slide) => (
                <div key={slide}>
                  <img className={classes.slide} src={slide} alt="Locator" />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </Grid>
      <Grid item>
        <Grid
          container
          justifyContent="center"
          className={classes.controls}
        >
          {slides.map((slide, i) => {
            let className = classes.controlItem

            if (i === selectedItem) {
              className = classes.controlItemSelected
            }

            return (
              <Grid
                key={slide}
                item
                className={className}
                onClick={() => {
                  onChange(i)
                }}
              />
            )
          })}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CustomCarousel
